<template>
  <div>
    <!-- <div style="margin: 0 10%"> -->
    <img
      :src="images[0]"
      style="width: 100%; height: 100%; align-items: center"
    />
    <!-- </div>
    <div style="margin: 0 10%"> -->
    <img
      :src="images[1]"
      style="width: 100%; height: 100%; align-items: center"
    />
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'Solution1',
  data() {
    return {
      images: [
        require('@/assets/image/solution/solution1/znl1.png'),
        require('@/assets/image/solution/solution1/znl2.png'),
      ],
    };
  },
};
</script>
